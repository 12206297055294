import React, { useEffect } from 'react';
import '../LoadingPage/LoadingPageStyles.scss';
import load from '../../Assets/Icons/load-single.png';
import load1 from '../../Assets/Icons/load1.png';
import load2 from '../../Assets/Icons/load2.png';
import load3 from '../../Assets/Icons/load3.png';
import { useState } from 'react';

const LoadingPage = () => {
  const [value, setValue] = useState('');
  const getAnim = () => {
    if (value === '') {
      return (
        <div className='loading-anim'>
          <img alt='' src={load} className='image-1' />
          <img alt='' src={load1} className='image-2' />
          <img alt='' src={load2} className='image-3' />
          <img alt='' src={load} className='image-4' />
          <img alt='' src={load3} className='image-5' />
          <img alt='' src={window.screen.width<600?load2:load} className='image-6' />
          <img alt='' src={load2} className='image-7' />
        </div>
      );
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setValue("reload");
    }, 1800);
    setValue("");

    return () => clearInterval(interval);
  }, [value]);

  return <div className='LoadingPage'>{getAnim()}</div>;
};
export default LoadingPage;
