import React, { useState, useRef, useEffect } from "react";
import "./../../SignUpFlow.scss";
import InputContainer from "../../../Signup/InputContainer/InputContainer";
// import UserPic from "../../../../../Assets/Animations/User.json";
// import Camera from "../../../../../Assets/Animations/add pic.json";
// import Delete from "../../../../../Assets/Animations/delete_lottie.json";
// import Edits from "../../../../../Assets/Animations/edit_lottie.json";
// import Lottie from "lottie-react";
import uploadIcon from "../../../../../Assets/Icons/add-3.svg";
import check from "../../../../../Assets/Icons/checked.png";
import cross from "../../../../../Assets/Icons/cross.png";
import { checkUsername } from "../../API_reqs";
import { useSignUpFlowContext } from "../../GlobalStates";
import { authService } from "../../../../../services/AuthService";

const CreateAccount = (props) => {
  const [pwdLength, setPwdLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [numVal, setNumVal] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [src, setSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [displayWidth, setDisplayWidth] = useState(0);
  const [usernameSuccessText, setUsernameSuccessText] = useState("")

  const [usernameSuccess, setUsernameSuccess] = useState(false)
  const fileInputRef = useRef(null);
  // const userAvailablity = useRef(null);

  const {
    selectedPage,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    setPassDone,
    passwordError,
    setPasswordError,
    confirmPasswordError,
    setConfirmPasswordError,
    passwordErrorText,
    confirmPasswordErrorText,
    address,
    setAddress,
    addressError,
    setAddressError,
    addressErrorText,
    fname,
    setFname,
    fnameError,
    setFnameError,
    fnameErrorText,
    lname,
    setLname,
    lnameError,
    setLnameError,
    lnameErrorText,
    username,
    setUsername,
    usernameError,
    setUsernameError,
    usernameErrorText,
    setUsernameErrorText,
    headline,
    setHeadline,
    headlineError,
    setHeadlineError,
    headlineErrorText,
    // base64Image,
    setBase64Image,
    selectDomain,
    headerHeight,
    footerHeight,
    pageWidth,
    setPageWidth,
  } = useSignUpFlowContext();

  useEffect(() => {
    setDisplayWidth(window.innerWidth);
    if (username !== "") {
      checkUsernameAvailability(username);
    } else {
      setUsernameError(false)
      setUsernameErrorText("");
      setUsernameSuccess(false)
      setUsernameSuccessText("")
    }
  }, [username]);
  const checkUsernameAvailability = (uname) => {
    let name = `${uname}@${selectDomain}`;
    if (uname !== "") {
      checkUsername(name).then((res) => {
        if (res.data.available) {
          if (authService().checkRestrictedUsername(uname)) {
            setUsernameSuccess(true)
            setUsernameSuccessText(`Username is available`)
          } else {
            setUsernameSuccess(false)
            setUsernameSuccessText("")
            setUsernameError(true)
            setUsernameErrorText("Restricted username");
          }
        } else {
          setUsernameError(true)
          setUsernameSuccess(false)
          setUsernameSuccessText("")
          setUsernameErrorText("X This username has already been taken")
        }
      });
    } else {
      setUsernameSuccess(false)
      setUsernameSuccessText("")
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file, src);
    if (file !== undefined) {
      const imageUrl = URL.createObjectURL(file);
      setSrc(imageUrl);
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image1 = reader.result;
        setBase64Image(base64Image1);
      };

      reader.readAsDataURL(file);
    } else {
      setSrc(null);
    }
    setSelectedFile(file);
  };

  const handleButtonClick = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };

  const passwordValidation = (passwordValue) => {
    if (/[A-Z]/.test(passwordValue)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }
    if (/[a-z]/.test(passwordValue)) {
      setLowerCase(true);
    } else {
      setLowerCase(false);
    }
    if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue)) {
      setSpecialChar(true);
    } else {
      setSpecialChar(false);
    }

    if (/\d/.test(passwordValue)) {
      setNumVal(true);
    } else {
      setNumVal(false);
    }
    if (passwordValue.length < 8) {
      setPwdLength(false);
    } else {
      setPwdLength(true);
    }
  };

  const passwordRequirements = (passwordValue) => {
    if (
      /[A-Z]/.test(passwordValue) &&
      /[a-z]/.test(passwordValue) &&
      /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue) &&
      /\d/.test(passwordValue) &&
      passwordValue.length >= 8
    ) {
      setPassDone(true);
    } else {
      setPassDone(false);
    }
  };

  return (
    <div
      style={{
        paddingBottom: displayWidth > 500 && `${footerHeight + 10}px`,
        paddingTop: displayWidth > 500 && displayWidth < 1400 && `${headerHeight + 90}px`,
        flexDirection: displayWidth > 500 && "column",
      }}
      className={
        props.CreateAccount
          ? "business-details-wrapper create-account-width"
          : "business-details-wrapper"
      }
    >
      {selectedPage === 9 && (
        <h2 className="your-details-header">Admin Account</h2>
      )}
      <div
        style={{ width: props.CreateAccount ? "100%" : "" }}
        className="main-wrapper"
      >
        <div className="inputs-wrapper">
          <div className="your-details-name-container">
            <div className="your-details-name-inputs">
              <InputContainer
                signUp
                color="white"
                height="4.18rem"
                fontSize="1rem"
                marginTop="0.6rem"
                placeholder="First Name"
                value={fname}
                type="firstName"
                onChange={(e) => {
                  setFname(e.target.value);
                  setFnameError(false);
                }}
                error={fnameError}
                errorText={fnameErrorText}
              />
            </div>
            <div className="your-details-name-inputs">
              <InputContainer
                signUp
                color="white"
                height="4.18rem"
                fontSize="1rem"
                marginTop="0.6rem"
                placeholder="Last Name"
                value={lname}
                type="lastname"
                onChange={(e) => {
                  setLname(e.target.value);
                  setLnameError(false);
                }}
                error={lnameError}
                errorText={lnameErrorText}
              />
            </div>
          </div>
          <InputContainer
            signUp
            signUpFlow
            display="flex"
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.688rem"
            placeholder="Address"
            value={address}
            //   type="side-button"
            onChange={(e) => {
              setAddress(e.target.value);
              setAddressError(false);
            }}
            error={addressError}
            errorText={addressErrorText}
          />
          <InputContainer
            signUp
            signUpFlow
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.688rem"
            placeholder="User Name"
            value={username.toLowerCase()}
            type="username"
            display="flex"
            onChange={(e) => {
              setUsername(e.target.value);
              setUsernameError(false);
              setUsernameErrorText("")
              setUsernameSuccess(false)
              setUsernameSuccessText("")
              checkUsernameAvailability(e.target.value);
            }}
            error={usernameError}
            errorText={usernameErrorText}
            success={usernameSuccess}
            successText={usernameSuccessText}
          />

          <InputContainer
            signUp
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.688rem"
            placeholder="Password"
            value={password}
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(false);
              passwordValidation(e.target.value);
              passwordRequirements(e.target.value);
            }}
            error={passwordError}
            errorText={passwordErrorText}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={() => {
              setIsFocus(false);
            }}
          />
          {isFocus && (
            <div className="condition-strength-2">
              <div className={upperCase ? "item" : "item2"}>
                {" "}
                {upperCase ? (
                  <img src={check} alt="" className="danger-image2" />
                ) : (
                  <img src={cross} alt="" className="danger-image2" />
                )}
                Contains an uppercase letter
              </div>
              <div className={lowerCase ? "item" : "item2"}>
                {lowerCase ? (
                  <img src={check} alt="" className="danger-image2" />
                ) : (
                  <img src={cross} alt="" className="danger-image2" />
                )}
                Contains a lowercase letter
              </div>
              <div className={numVal ? "item" : "item2"}>
                {numVal ? (
                  <img src={check} alt="" className="danger-image2" />
                ) : (
                  <img src={cross} alt="" className="danger-image2" />
                )}
                Contains a number
              </div>
              <div className={specialChar ? "item" : "item2"}>
                {specialChar ? (
                  <img src={check} alt="" className="danger-image2" />
                ) : (
                  <img src={cross} alt="" className="danger-image2" />
                )}
                Contains a special character
              </div>
              <div className={pwdLength ? "item" : "item2"}>
                {pwdLength ? (
                  <img src={check} alt="" className="danger-image2" />
                ) : (
                  <img src={cross} alt="" className="danger-image2" />
                )}
                Password strength 8 characters
              </div>
            </div>
          )}
          <InputContainer
            signUp
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.688rem"
            placeholder="Confirm Password"
            value={confirmPassword}
            type="confirmPass"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordError(false);
            }}
            error={confirmPasswordError}
            errorText={confirmPasswordErrorText}
          />
          <InputContainer
            signUp
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.688rem"
            placeholder="Headline"
            value={headline}
            type="text"
            onChange={(e) => {
              setHeadline(e.target.value);
              setHeadlineError(false);
            }}
            error={headlineError}
            errorText={headlineErrorText}
          />
        </div>
        {/* <div
          className="profile-pic-wrapper"
          style={{ backgroundColor: src !== null ? "black" : "" }}
        >
          <div>
            {selectedFile ? (
              <div
                className="profile-pic"
                style={{ backgroundImage: `url(${src})` }}
                height="auto"
                alt="Selected Profile"
              ></div>
            ) : (
              <Lottie
                animationData={UserPic}
                loop={false}
                onClick={() => {
                  setSelectedFile(null);
                  setSrc(null);
                }}
                className="account-image"
              />
              // <img src={UserPic} alt="" style={{ height:"16.8rem" }} />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />

            {selectedFile ? (
              <>
                <Lottie
                  animationData={Edits}
                  loop={true}
                  onClick={handleButtonClick}
                  className="select-icon"
                />
                <Lottie
                  animationData={Delete}
                  loop={false}
                  onClick={() => {
                    setSelectedFile(null);
                    setSrc(null);
                    console.log("null");
                    setBase64Image("");
                  }}
                  className="select-icon"
                />
              </>
            ) : (
              <Lottie
                animationData={Camera}
                loop={true}
                onClick={handleButtonClick}
                className="select-icon"
              />
            )}
          </div>
        </div> */}

        {selectedFile ? (
          <>
            <div
              className="dp-wrapper"
              style={{
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setSelectedFile(null);
                setSrc(null);
                console.log("null");
                setBase64Image("");
              }}
            >
              <div className="upload-photo">
                <img className="dp" src={src} />
                <div className="dp-overlayer">Remove Photo</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="dp-wrapper" onClick={handleButtonClick}>
              <div className="upload-photo">
                <img className="upload" src={uploadIcon} alt="" />
                <div>Upload Image</div>
              </div>
            </div>
          </>
        )}

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};
export default CreateAccount;
