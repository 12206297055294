import React, { useRef } from "react";
import { useHistory} from "react-router-dom";
import AppContext from "../../../ContextApi/AppContext";
import "./ForgotPasswordLinkStyles.scss";
import Carousel from "../../../Components/Carousel/Carousel";
import Button from "../../../Components/Button/Button";

const ForgotPasswordLink = () => {

    const history = useHistory();
    const passwordLinkBtn = useRef(null);



    return (
        <AppContext.Consumer>
            {(context) => (
                <div>

                    <div className="_passwordLinkContainer">
                        <div className="_passwordLinkInnerContainerLeft">
                            <div className="wrapper">
                            <div className="bixHeader">ZapMail</div>
                                <div className="header-bottom-text">A smarter way to connect.</div>
                                <div className="form-wrapper">
                                    <div className="input-wrapper username-margin-top-passwordLink">
                                        <div className="passwordLinkLabel">Forgot Password</div>
                                    </div>
                                    <div className="bullet-point">
                                        <span className="bullet" /><div><div>
                                            If you want to reset password, Contact the admin of your  organization
                                        </div></div>
                                    </div>
                                    <div>
                                        <Button
                                            ref={passwordLinkBtn}
                                            loading={context?.passwordLinkIsLoading}
                                            buttonText="Back"
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/",
                                                });

                                            }}
                                        />
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="_passwordLinkInnerContainerRight">
                            <div className="wrapper2">
                                <Carousel />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );
};
export default ForgotPasswordLink;
