import React, { useEffect, useState } from "react";
import check from "../../../Assets/Icons/tickNew.png";
import "./index.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import { useHistory } from "react-router-dom";

const Upsell = (props) => {
  useEffect(() => {
  });
  const {
    selectedItem,
    setSelectedItem,
    upsellSelected,
    setUpsellSelected,
    zappyUpsell,
    setZappyUpsell,
    bulkUpsell,
    setBulkUpsell,
  } = useSignUpFlowContext();

  const history = useHistory();
  const [upsell2, setUpsell2] = useState(false);

  const handleNext = () => {
    if(upsell2){
      history.push("/confirm/up")
    }
    else {
      history.push("/signup-flow-details")
      localStorage.setItem("upsellSelected", upsellSelected)   
    } 
  }

  const handleZappySelect = () => {
    setZappyUpsell(!zappyUpsell)
    setUpsellSelected((!zappyUpsell || bulkUpsell))
  }

  const handleBulkSelect = () => {
    setBulkUpsell(!bulkUpsell)
    setUpsellSelected((zappyUpsell || !bulkUpsell))
  }

  useEffect(() => {
    if(localStorage.getItem('upsellSelected') == 'false'){
      setUpsell2(true)
    }
  }, []);

  useEffect(() => {
    if(zappyUpsell && bulkUpsell) setSelectedItem('AI Website + Bulk Email')
    else{
      setSelectedItem('')
      if(zappyUpsell) setSelectedItem('AI Website')
      if(bulkUpsell) setSelectedItem('Bulk Email')
    }
  }, [zappyUpsell, bulkUpsell]);
  
  return (
    <div className="main-body">
      <div className={upsellSelected ? "floating-button fb-selected" : "floating-button"} onClick={handleNext}>
        <div className="floating-title">
          {upsellSelected ?
            "Proceed to claim offer" :
            "No thanks, I'll skip the offer"}
        </div>
        <div className="floating-footer">
          {upsell2 ? '' : 'Proceed to setup business emails'}
        </div>
      </div>
      <div className={upsellSelected ? "body-content bc-selected" : "body-content"}>
        <div className="title">
          Upgrade now!
        </div>
        <div className="top-text">
        Save 50% off from your monthly subscription for lifetime*
        </div>
        <div className="content-card" onClick={handleZappySelect}>
          <div className="card-top">
            <div className="card-top-left">
              <div className="card-title">
                AI Website
              </div>
              <div className="card-price">
                <div className="price-normal">
                  $20
                </div>
                <div className="price-discount">
                  $10
                </div>
              </div>
            </div>
            <div className="card-top-right">
              <div className={zappyUpsell ? "select-box selected" : "select-box"}>
                {zappyUpsell ? <img src={check} className="img-check"/> : null}
              </div>
            </div>
          </div>
          <div className="card-content">
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Launch on your domain</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>500 replies monthly</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Unlimited lead collection</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Update your info anytime</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>All conversation history</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>WhatsApp integration</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Analytics dashboard</p></div>
          </div>
          <div className={zappyUpsell ? "selected select-button" : "select-button"}>
            <div className={zappyUpsell ? "selected select-text" : "select-text"}>
              {zappyUpsell ? "Selected" : "Select Offer"}
            </div>
          </div>
          <div className="bottom-text">
            Cancel anytime <br />
            30 day money back guarantee
          </div>
        </div>
        <div className="content-card" onClick={handleBulkSelect}>
          <div className="card-top">
            <div className="card-top-left">
              <div className="card-title">
                Bulk Email Sending
              </div>
              <div className="card-price">
                <div className="price-normal">
                  $10
                </div>
                <div className="price-discount">
                  $5
                </div>
              </div>
            </div>
            <div className="card-top-right">
            <div className={bulkUpsell ? "select-box selected" : "select-box"}>
              {bulkUpsell ? <img src={check} className="img-check"/> : null}
              </div>
            </div>
          </div>
          <div className="card-content">
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Upto 500k emails per list</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>2500 Email credits monthly</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Unlimited personalisation</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Analytics dashboard</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Dynamic lists</p></div>
            <div style={{ display: 'flex', flexDirection: 'row'}}><p style={{color: 'green', fontWeight: 'bold', marginRight: '5px'}}>✓</p><p>Lead form</p></div>
          </div>
          <div className={bulkUpsell ? "selected select-button" : "select-button"}>
            <div className={bulkUpsell ? "selected select-text" : "select-text"}>
              {bulkUpsell ? "Selected" : "Select Offer"}
            </div>
          </div>
          <div className="bottom-text">
            Cancel anytime <br />
            30 day money back guarantee
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upsell;
