import CookieService from "./CookieService";
import config from "../config";
export const authService = () => {
  let isFound = false;
  let users = [];
  let date = new Date();
  let newDate = new Date(date.setMonth(date.getMonth() + 1));
  const storeUsersInLocalStorage = (user) => {
    let zapmailUsers = JSON.parse(
      localStorage.getItem("zapmail_users") || "[]"
    );
    let isUserFound = false;
    if (users.length > 1) {
      isUserFound = zapmailUsers.some(
        (zapmailUser) => zapmailUser.public_id === user.public_id
      );
      if (!isUserFound) {
        zapmailUsers.push(user);
      }
    } else {
      zapmailUsers.push(user);
    }
    localStorage.setItem("zapmail_users", JSON.stringify(zapmailUsers));
  };
  const handleLogin = (response) => {
    return new Promise((resolve) => {
      let data = response;
      let cookieUsers = CookieService.get("users");
      if (cookieUsers) {
        users = cookieUsers;
      }
      if (users) {
        isFound = users.some((user) => user.public_id === data.public_id);
      }
      let full_name = data.first_name + " " + data.last_name;
      let userObject = {
        public_id: data.public_id,
        fullname: full_name,
        email: data.email,
      };
      if (!isFound) {
        users.push(userObject);
      }
      storeUsersInLocalStorage(data);
      const options = {
        path: "/",
        domain: config.cookieDomain,
        expires: newDate,
      };
      CookieService.set("users", JSON.stringify(users), options);
      CookieService.set("user", JSON.stringify(data), options);
      CookieService.set("bixAccess_token", data.x_access_token, options);
      resolve(true);
    });
  };
  const handlePasswordReset = (setResetSuccess, setResetIsLoading) => {
    return new Promise((resolve) => {
      let user = CookieService.get("user");
      if (user) {
        setResetSuccess(true);
        setResetIsLoading(false);
        let is_first_login = localStorage.getItem("is_first_login")
        user.is_first_login = is_first_login === "true" ? true : false;
        const options = {
          path: "/",
          domain: config.cookieDomain,
          expires: newDate,
        };
        CookieService.set("user", user, options);
        CookieService.set("bixAccess_token", user.x_access_token, options);
        resolve(true);
      }
    });
  };
  const handleMultipleUserLogout = (publicId) => {
    return new Promise((resolve) => {
      let users = CookieService.get("users");
      const options = {
        path: "/",
        domain: config.cookieDomain,
        expires: newDate,
      };
      if (users) {
        let newUsers = users.filter((user) => user.public_id !== publicId);
        CookieService.set("users", newUsers, options);
        let localStorageUsers = JSON.parse(
          localStorage.getItem("zapmail_users") || "[]"
        );
        if (localStorageUsers.length > 0) {
          let filteredlocalStorageUsers = localStorageUsers.filter(
            (user) => user.public_id !== publicId
          );
          localStorage.setItem(
            "zapmail_users",
            JSON.stringify(filteredlocalStorageUsers)
          );
          CookieService.set("user", filteredlocalStorageUsers[0], options);
          resolve(true);
        } else {
          resolve(false)
        }
      }
    });
  };
  const handleSwitchingUser = (publicId) => {
    return new Promise((resolve) => {
      let users = CookieService.get("users");
      let firstLogin = CookieService.get("user");
      const options = {
        path: "/",
        domain: config.cookieDomain,
        expires: newDate,
      };
      if (users) {
        let localStorageUsers = JSON.parse(
          localStorage.getItem("zapmail_users") || "[]"
        );
        console.log(localStorageUsers, "localStorageUsers switching");
        if (localStorageUsers.length > 0) {
          let updateLogout = localStorageUsers?.find(
            (user) => user.public_id === firstLogin.public_id
          );
          if (updateLogout) {
            updateLogout.is_first_login = false;
          }
          localStorage.setItem(
            "zapmail_users",
            JSON.stringify(localStorageUsers)
          );
          let switchingUser = localStorageUsers?.find(
            (user) => user.public_id === publicId
          );
          CookieService.set("user", switchingUser, options);
          resolve(true);
        } else {
          resolve(false)
        }
      }
    });
  };
  const checkRestrictedUsername = (username) => {
    let restrictedKeywords = [
      'admin', 'support', 'info', 'contact', 'help', 'webmaster', 'abuse', 'noreply', 'postmaster',
      'sales', 'billing', 'contactus', 'security', 'marketing', 'feedback', 'privacy', 'root', 'contact',
      'service', 'contactinfo', 'accounts', 'zapmail', 'Register', 'billing info', 'unsubscribe', 'techsupport',
      'purchase', 'helpdesk', 'member', 'hello', 'careers', 'password-reset', 'passwordreset', 'password',
      'Passwords', 'unsubscribe', 'newsletter', 'username'
    ];
    // Check if the username is in the restricted keywords list (case-insensitive)
    let lowercasedUsername = username.toLowerCase();
    return !restrictedKeywords.includes(lowercasedUsername);
  }
  return {
    handleLogin,
    handlePasswordReset,
    handleMultipleUserLogout,
    handleSwitchingUser,
    checkRestrictedUsername
  };
};
