import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../../../ContextApi/AppContext";
import "./PasswordChangeStyles.scss";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import UsernameInput from "../../../Components/UsernameInput/UsernameInput";
import logo from "../../../Assets/Images/logo.png";
import Button from "../../../Components/Button/Button";
import check from "../../../Assets/Icons/checked.png";
import cross from "../../../Assets/Icons/cross.png";
import exp from "../../../Assets/Icons/linkexp.png";


const PasswordChange = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const forgotBtn = useRef(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false);
  const [isConfirmPasswordFocus, setIsConfirmPasswordFocus] = useState(false);
  const location = useLocation();
  const [pwdLength, setPwdLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [numVal, setNumVal] = useState(false);
  const [passwordInputStatus, setPasswordInputStatus] = useState(0);
  const [confirmPassStatus, setConfirmPassStatus] = useState(0);
  const [userNameError, setUserNameError] = useState(false);
  const newContext = useContext(AppContext);
  const [pwdFormat, setPwdFormat] = useState(false);

  const handleUsername = (e) => {
    let userNameValue = e.target.value.trim();
    if (userNameError) {
      if (userNameValue !== "") {
        setUserNameError(false);
      }
    }
    setUserName(userNameValue);
  };
  useEffect(() => {
    let token = window.location.href.split("?token=")[1];
    if (token !== undefined || token !== "") {
      newContext.checkLinkExpiration(token)
      localStorage.setItem("bixChat_resetToken", token);
    }
  }, []);
  const passwordValidation = () => {
    let passwordValue = newPassword;
    if (passwordValue.length === 0 || passwordValue === "") {
      setPasswordInputStatus(0);
      setPwdLength(false);
      setLowerCase(false);
      setUpperCase(false);
      setSpecialChar(false);
      setNumVal(false);
    } else {
      if (/[A-Z]/.test(passwordValue)) {
        setUpperCase(true);
      } else {
        setUpperCase(false);
      }
      if (/[a-z]/.test(passwordValue)) {
        setLowerCase(true);
      } else {
        setLowerCase(false);
      }
      if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue)) {
        setSpecialChar(true);
      } else {
        setSpecialChar(false);
      }

      if (/\d/.test(passwordValue)) {
        setNumVal(true);
      } else {
        setNumVal(false);
      }
      if (passwordValue.length < 8) {
        setPwdLength(false);
      } else {
        setPwdLength(true);
      }
      if (
        passwordValue.length >= 8 &&
        numVal &&
        upperCase &&
        lowerCase &&
        specialChar
      ) {
        setPasswordInputStatus(1);
        setPwdFormat(false);
      } else {
        setPasswordInputStatus(0);
        setPwdFormat(true);
      }
    }
  };
  useEffect(() => {
    passwordValidation();
  }, [newPassword]);
  const handleConfirm = (e) => {
    let confirm_password = e.target.value;
    if (confirmPassword.length > 0) {
      if (confirmPassword !== newPassword) {
        setConfirmPassStatus(2);
      } else {
        setConfirmPassStatus(1);
      }
    } else {
      setConfirmPassStatus(0);
    }
    if (confirmPasswordError) {
      if (confirm_password !== "") {
        setConfirmPasswordError(false);
      }
    }
    setConfirmPassword(confirm_password);
  };
  useEffect(() => {
    if (
      newPassword.length >= 8 &&
      numVal &&
      upperCase &&
      lowerCase &&
      specialChar
    ) {
      setPasswordInputStatus(1);
    }
  });
  const handlePassword = (e) => {
    let passwordValue = e.target.value;
    if (pwdError) {
      if (passwordValue !== "") {
        setPwdError(false);
      }
    }
    setNewPassword(passwordValue);
  };
  const confirmPassValidation = () => {
    if (confirmPassword.length > 0) {
      if (confirmPassword !== newPassword) {
        return (
          <div className="form-fail">
            <img src={danger} alt="" className="danger-login" />
            <p>Please make sure passwords match.</p>
          </div>
        );
      } else if (confirmPassStatus === 1) {
        return "";
      }
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        if (newPassword === "" && confirmPassword === "") {
          setPwdError(true);
          setConfirmPasswordError(true);
        } else if (newPassword === "" && confirmPassword !== "") {
          setPwdError(true);
        } else if (newPassword !== "" && confirmPassword === "") {
          setConfirmPasswordError(true);
        } else {
          setShowPassword(false);
          setPwdError(false);
          setConfirmPasswordError(false);
          forgotBtn.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [newPassword, confirmPassword]);
  const removeFocus = () => {
    setIsUsernameFocus(false);
    setIsNewPasswordFocus(false);
    setIsConfirmPasswordFocus(false);
  };
  useEffect(() => {
    if (newContext.passwordChangeLinkError) {
      setUserName("");
      setNewPassword("");
      setConfirmPassword("");
    }
  }, [newContext.passwordChangeLinkError]);
  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="passwordChange">
          <div className="_passwordChangeContainer">
            <div className="form-wrapper">
              <div className="header-container">
                <img alt="" src={logo} className="image" />
              </div>
              <div className="passwordChangewrapper">
                <div className="header">Reset Password</div>
                {context.isLinkExpired &&
                  <div className="expired">
                    <img alt="" src={exp} className="linkexp" />
                    Link has expired. Request a new password reset</div>}
                <div className="input-wrapper">
                  <UsernameInput
                    type="text"
                    isUsernameFocus={isUsernameFocus}
                    placeholder={isUsernameFocus ? "" : "Email"}
                    value={userName}
                    onChange={(e) => {
                      context?.setPasswordLinkError(false);
                      context?.setLoggedFailed(false);
                      handleUsername(e);
                    }}
                    userNameError={userNameError}
                    onFocus={() => setIsUsernameFocus(true)}
                    onBlur={() => setIsUsernameFocus(false)}
                    name="username"
                    from={location?.state?.from}
                  />
                </div>
                {userNameError ? (
                  <div className="form-fail">* Required</div>
                ) : (
                  ""
                )}
                {context.passwordChangeLinkError ? (
                  <div className="form-fail">
                    <img src={danger} alt="" className="danger-login" />
                    Invalid Email
                  </div>
                ) : (
                  ""
                )}

                <div className="input-wrapper">
                  <UsernameInput
                    type={showPassword ? "text" : "password"}
                    placeholder={isNewPasswordFocus ? "" : "New Password"}
                    onChange={(e) => {
                      handlePassword(e);
                    }}
                    isPwdFocus={isNewPasswordFocus}
                    pwdError={pwdError}
                    onFocus={() => setIsNewPasswordFocus(true)}
                    onBlur={() => setIsNewPasswordFocus(false)}
                    name="password"
                    value={newPassword}
                  />
                  {pwdError ? (
                    <div className="form-fail">
                      * Required
                    </div>
                  ) : (
                    ""
                  )}
                  {pwdFormat &&
                    !isNewPasswordFocus &&
                    newPassword.length !== 0 ? (
                    <div className="form-fail">
                      <img src={danger} alt="" className="danger-login" />
                      The Password does not meet requirements
                    </div>
                  ) : (
                    ""
                  )}
                  {isNewPasswordFocus &&
                    newPassword.length !== 0 &&
                    passwordInputStatus !== 1 ? (
                    <div className="condition-strength-2">
                      <div className={upperCase ? "item" : "item2"}>
                        {" "}
                        {upperCase ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains an uppercase letter
                      </div>
                      <div className={lowerCase ? "item" : "item2"}>
                        {lowerCase ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains a lowercase letter
                      </div>
                      <div className={numVal ? "item" : "item2"}>
                        {numVal ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains a number
                      </div>
                      <div className={specialChar ? "item" : "item2"}>
                        {specialChar ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains a special character
                      </div>
                      <div className={pwdLength ? "item" : "item2"}>
                        {pwdLength ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Password strength 8 characters
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="input-wrapper">
                  <UsernameInput
                    type={showPassword ? "text" : "password"}
                    placeholder={
                      isConfirmPasswordFocus ? "" : "Confirm Password"
                    }
                    onChange={(e) => {
                      handleConfirm(e);
                    }}
                    isPwdFocus={isConfirmPasswordFocus}
                    pwdError={confirmPasswordError}
                    onFocus={() => setIsConfirmPasswordFocus(true)}
                    onBlur={() => setIsConfirmPasswordFocus(false)}
                    name="password"
                    value={confirmPassword}
                  />
                </div>
                {confirmPassValidation()}
                <div className="margin" />
                <Button
                  ref={forgotBtn}
                  loading={context?.changePasswordIsLoading}
                  buttonText="Reset Password"
                  onClick={() => {
                    if (!context.isLinkExpired) {
                      removeFocus();
                      context.changePassword(
                        userName.toLowerCase(),
                        newPassword,
                        confirmPassword
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default PasswordChange;
