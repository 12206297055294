import React, { useState, useEffect } from "react";
import AppContext from "../../ContextApi/AppContext";
import userIcon from "../../Assets/Icons/show.png";
import pwdIcon2 from "../../Assets/Icons/unshow.png";

import "./UsernameInputStyles.scss";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const UsernameInput = (props) => {
  const [showPwd, setShowPwd] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);







  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="UsernameInput">
          <div className="zp-input-container">
            <input
              type={showPwd ? "text" : props.type}
              className="input-wrapper"
              name={props.name}
              id="fname"
              placeholder={props.placeholder}
              onChange={props.onChange}
              value={props.value}
              onFocus={() => {
                props.onFocus();
              }}
              onBlur={() => {
                props.onBlur();
              }}
              onPaste={props.onPaste}
              maxLength={props.maxLength ? props.maxLength : ""}
            />

            <div className="icon-container">
              {props.name === "password" ? (
                <span
                  for="fname"
                  className="icon"
                  onClick={() => {
                    if (showPwd) {
                      setShowPwd(false);
                    } else {
                      setShowPwd(true);
                    }
                  }}
                >
                  {showPwd ? (
                    <img src={pwdIcon2} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </span>
              ) : (
                ""
              )}

              {props.name === "confirmPass" ? (
                <span
                  for="fname"
                  className="icon"
                  onClick={() => {
                    if (showPwd) {
                      setShowPwd(false);
                    } else {
                      setShowPwd(true);
                    }
                  }}
                >
                  {showPwd ? (
                    <img src={pwdIcon2} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default UsernameInput;
