import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../config";
import AppContext from "./AppContext";
//Helper Classes
import { restHelper } from "./Helper/RestHelper";
import { authService } from "../services/AuthService";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const AppProvider = (props) => {
  const api = restHelper();
  //states
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [loggedFailed, setLoggedFailed] = useState(false);
  const [forgetPwdLoading, setForgetPwdLoading] = useState(false);
  const [forgetPwdFailed, setforgetPwdFailed] = useState(false);
  const [loggingSuccess, setLoggingSuccess] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [changePasswordIsLoading, setChangePasswordIsLoading] = useState(false);
  const [passwordLinkError, setPasswordLinkError] = useState(false);
  const [isPublicDomainLoading, setIsPublicDomainLoading] = useState(false)
  const [publicDomains, setPublicDomains] = useState([])
  const [signupLoading, setSignupLoading] = useState(false)
  const [isLinkExpired, setIsLinkExpired] = useState(false)
  const [forgetIsLoading, setForgetIsLoading] = useState(false)





  //history
  const history = useHistory();

  const loginClicked = async (userName, password) => {
    setLoginIsLoading(true);

    let credential = {
      username: `${userName}`,
      password: password,
    };
    let options = {
      body: credential,
    };
    let url = "login";
    api
      .postCreate(url, options)
      .then(async (response) => {
        if (response.status === 200) {
          let data = response.data;
          await authService()
            .handleLogin(data, true)
            .then(() => {
              localStorage.setItem(
                "bixauth_accesstoken",
                data.x_access_token
              );
              if (data.is_first_login || data.is_password_reset) {
                localStorage.setItem("token", data.refresh_token);
                localStorage.setItem("is_first_login", data.is_first_login);
                localStorage.setItem("bixauth_email", data.email);
                history.push({
                  pathname: "/reset-password",
                });
              } else if (data.role === "ADMIN" && data.recovery_email === "") {
                history.push({
                  pathname: "/recovery-email",
                  state: {
                    from: 'login'
                  }
                });
              } else {
                window.location.href = `${config.domain.app}`;
              }
            });
        } else {
          setLoginIsLoading(false);
          setLoggedFailed(true);
        }
      })
      .catch((error) => {
        setLoginIsLoading(false);
        setLoggedFailed(true);
      });
  };

  const resetPassword = (password, confirmPassword) => {
    let refreshToken = localStorage.getItem("token");
    setResetIsLoading(true);
    let credential = {
      email: localStorage.getItem("bixauth_email"),
      password: password,
      confirm_password: confirmPassword,
    };
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${refreshToken}`,
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "change_password_1st_login",
      data: credential,
    })
      .then(async (response) => {
        if (response.status === 200) {
          await authService()
            .handlePasswordReset(setResetSuccess, setResetIsLoading)
            .then((resetResponse) => {
              if (resetResponse) {
                localStorage.removeItem("is_first_login");
                window.location.href = `${config.domain.app}`;
              }
            });
        }
      })
      .catch((error) => { });
  };
  const changePassword = (email, password, confirmPassword) => {
    setChangePasswordIsLoading(true);
    let credential = {
      email: email,
      password: password,
      confirm_password: confirmPassword,
    };
    let refreshToken = localStorage?.getItem("bixChat_resetToken");
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${refreshToken}`,
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "reset_password",
      data: credential,
    })
      .then(async (response) => {
        setChangePasswordIsLoading(false);
        if (response.status === 200) {
          let data = response.data;
          await authService()
            .handleLogin(data, true)
            .then(async () => {
              localStorage.setItem("is_first_login", data.is_first_login);
              await authService()
                .handlePasswordReset(setResetSuccess, setResetIsLoading)
                .then((resetResponse) => {
                  if (resetResponse) {
                    localStorage.removeItem("is_first_login");
                    window.location.href = `${config.domain.app}`;
                  }
                });
            })

        } else if (response.status === 400) {
          setPasswordLinkError(true);
        } else {
          setIsLinkExpired(true)
        }
      })
      .catch((error) => {
        setIsLinkExpired(true)
      });
  };

  // Forget Password
  const forgetPassword = (email, password, confirmPassword, x_access_token) => {
    return new Promise((resolve, reject) => {
      setForgetPwdLoading(true);

      let credential = {
        email: email,
        password: password,
        confirm_password: confirmPassword,
      };

      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${x_access_token}`,
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/reset_password_by_otp",
        data: credential,
      })
        .then((response) => {
          if (response.status === 200) {
            setForgetPwdLoading(false);
            resolve(true)
          }


        })
        .catch((error) => {
          setForgetPwdLoading(false);
          setforgetPwdFailed(true);
          reject(error)
        });
    })

  };
  //get uId
  const getuID = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bix_accessToken"),
        },
        method: "post",
        baseURL: `https://api.bixchat.xyz/auth/user_get_uid`,
      })
        .then((response) => {
          console.log(response, "res of pre req");
          if (response.status === 200) {
            resolve(response);
          } else {
            localStorage.clear();
            localStorage.setItem("bix_authFrom", "bixChat");
            history.push({
              pathname: `/`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
  //validate Accesstoken
  const validateToken = (accessToken) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        baseURL: `https://api.bixchat.xyz/auth`,
        url: `validate`,
        headers: {
          "x-access-token": accessToken,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            localStorage.clear();
            localStorage.setItem("bix_authFrom", "bixChat");
            history.push({
              pathname: `/`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.clear();
          reject(error);
        });
    });
  };
  const getPublicDomains = () => {
    setIsPublicDomainLoading(true);
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "/domain/public",
    })
      .then(async (response) => {
        if (response.status === 200) {
          setPublicDomains(response.data.domains)
          setIsPublicDomainLoading(false)
        }
      })
      .catch((error) => {
        setIsPublicDomainLoading(false)
      });
  };
  const businessDomainSignup = (data) => {
    setSignupLoading(true);
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/user/business_signup",
        data: data
      })
        .then(async (response) => {
          if (response.status === 201) {
            let data = response.data;
            await authService()
              .handleLogin(data, true)
              .then(() => {
                if (data.is_first_login || data.is_password_reset) {
                  setFirstLoginFalse(data.x_access_token).then(
                    (result) => {
                      console.log("done", result);
                    }
                  );
                  localStorage.setItem(
                    "bixauth_accesstoken",
                    data.x_access_token
                  );
                  localStorage.setItem("token", data.refresh_token);
                  localStorage.setItem("is_first_login", data.is_first_login);
                  localStorage.setItem("bixauth_email", data.email);

                }
              });
            setSignupLoading(false)
            resolve(true)
          }
        })
        .catch((error) => {
          setSignupLoading(false)
          reject(error)
        });
    })
  };
  const publicDomainSignup = (data) => {
    setSignupLoading(true);
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/user/public_signup",
        data: data
      })
        .then(async (response) => {
          if (response.status === 200) {
            setSignupLoading(false)
            resolve(true)
          }
        })
        .catch((error) => {
          setSignupLoading(false)
          reject(error)
        });
    })
  };
  const checkUsernameAvalibility = (username) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "get",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: `/user/email_validation/${username}`,
      })
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data.available);
          }
        })
        .catch((error) => {
          reject(error)
        });
    })
  }
  const checkLinkExpiration = (refreshToken) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${refreshToken}`,
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "reset_link",
    })
      .then(async (response) => {
        if (response.status === 200) {
          setIsLinkExpired(false)
        } else {
          setIsLinkExpired(true)
        }
      })
      .catch((error) => {
        setIsLinkExpired(true)
      });
  }
  const setFirstLoginFalse = (token) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${token}`,
        },
        method: "put",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: `/user/first-login`
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getRecovery = (email) => {
    return new Promise((resolve) => {
      let credential = {
        email: email,
      };
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/reset_forget_password",
        data: credential,
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
        });
    })
  };
  const verifyResetOTP = (email, otp) => {
    return new Promise((resolve, reject) => {
      setForgetIsLoading(true)
      let credential = {
        email: email,
        otp: otp
      };
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/reset_password_verify_otp",
        data: credential,
      })
        .then((response) => {
          if (response.status === 200) {
            setForgetIsLoading(false)
            resolve(response.data)
          }
        })
        .catch((error) => {
          setForgetIsLoading(false)
          reject(error)
        });
    })
  };
  const recovery_email = (email) => {
    return new Promise((resolve, reject) => {
      setForgetIsLoading(true)
      let credential = {
        recovery_email: email,
      };
      let token = localStorage?.getItem("bixauth_accesstoken");
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/user/recovery_email_set_request",
        data: credential,
      })
        .then((response) => {
          if (response.status === 200) {
            setForgetIsLoading(false)
            resolve(response)
          }
        })
        .catch((error) => {
          setForgetIsLoading(false)
          reject(error)
        });
    })
  };
  const recovery_email_otp = (otp) => {
    return new Promise((resolve, reject) => {
      setForgetIsLoading(true)
      let credential = {
        otp: otp
      };
      let token = localStorage?.getItem("bixauth_accesstoken");
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        method: "put",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: "/user/recovery_email_set",
        data: credential,
      })
        .then((response) => {
          if (response.status === 201) {
            setForgetIsLoading(false)
            resolve(response)
          }
        })
        .catch((error) => {
          setForgetIsLoading(false)
          reject(error)
        });
    })
  };

  return (
    <AppContext.Provider
      value={{
        //states
        loggedFailed,
        setLoggedFailed,
        loginIsLoading,
        setLoginIsLoading,
        forgetPwdLoading,
        setforgetPwdFailed,
        forgetPwdFailed,
        setForgetPwdLoading,
        loggingSuccess,
        windowDimensions,
        setWindowDimensions,
        setLoggingSuccess,
        resetIsLoading,
        setResetIsLoading,
        resetSuccess,
        setResetSuccess,
        changePasswordIsLoading,
        setChangePasswordIsLoading,
        passwordLinkError,
        setPasswordLinkError,
        isPublicDomainLoading,
        setIsPublicDomainLoading,
        publicDomains,
        setPublicDomains,
        signupLoading,
        setSignupLoading,
        isLinkExpired,
        forgetIsLoading,
        setForgetIsLoading,

        //functions
        loginClicked,
        getuID,
        validateToken,
        resetPassword,
        changePassword,
        forgetPassword,
        getPublicDomains,
        publicDomainSignup,
        checkUsernameAvalibility,
        checkLinkExpiration,
        businessDomainSignup,
        setFirstLoginFalse,
        getRecovery,
        verifyResetOTP,
        recovery_email,
        recovery_email_otp
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export default AppProvider;
