import React, { useState, useEffect, useContext } from "react";
import "../SignupWrapper/SignupWrapperStyles.scss";
import logo from "../../../../Assets/Images/logo-new.png";
import back from "../../../../Assets/Icons/back.png";
import InputContainer from "../InputContainer/InputContainer";
import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";
import AppContext from "../../../../ContextApi/AppContext";
import { authService } from "../../../../services/AuthService";


const SignupWrapperThree = (props) => {
    const [usernameError, setUsernameError] = useState(false)
    const [usernameText, setUsernameText] = useState("* Required")
    const [usernameSuccessText, setUsernameSuccessText] = useState("")

    const [usernameSuccess, setUsernameSuccess] = useState(false)
    const context = useContext(AppContext)


    useEffect(() => {
        if (props.username !== "") {
            checkAvailability(props.username)
        } else {
            setUsernameSuccess(false)
            setUsernameSuccessText("")
        }

    }, [props.username])

    const checkAvailability = (uname) => {
        let name = `${uname}@${props.domain}`
        if (uname !== "") {
            context.checkUsernameAvalibility(name).then((available) => {
                if (available) {
                    if (authService().checkRestrictedUsername(uname)) {
                        setUsernameSuccess(true)
                        setUsernameError(false)
                        setUsernameSuccessText(`Username is available`)
                    } else {
                        setUsernameError(true)
                        setUsernameSuccess(false)
                        setUsernameSuccessText("")
                        setUsernameText("*Restricted username")
                    }
                } else {
                    setUsernameError(true)
                    setUsernameSuccess(false)
                    setUsernameSuccessText("")
                    setUsernameText("X This username has already been taken")
                }

            })

        } else {
            setUsernameSuccess(false)
            setUsernameSuccessText("")
        }
    }
    const validateEmail = () => {
        return props.username
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const onNextClick = () => {
        if (props.username !== "" && usernameSuccess) {
            if (validateEmail()) {
                setUsernameError(true)
                setUsernameSuccess(false)
                setUsernameText("*Invalid username")

            } else {
                props.setSelectedPage(5)
                setUsernameError(false)
                setUsernameSuccess(false)


            }
        } else if (props.username === "") {
            setUsernameError(true)
            setUsernameText("* Required")
            setUsernameSuccess(false)
        }
    }
    return (
        <div className="SignupWrapper">
            <div className="SignupWrapper-container">
                <div className="header-container">
                    <div className="logo-container">
                        <img alt="" src={back} className="back-icon" onClick={() => {
                            props.setSelectedPage(3)
                        }} />
                        <img alt="" src={logo} className="logo" />
                    </div>
                    <div className="signup-header">
                        Choose your Email Address
                    </div>
                    <div className="signup-subheader">
                    </div>
                    <div className="username-wrapper">
                        <div className="input-field">
                            <InputContainer placeholder="Username" value={props.username.toLowerCase()} type="username" onChange={e => {
                                props.setUsername(e.target.value)
                                setUsernameError(false)
                                setUsernameText("")
                                setUsernameSuccess(false)
                                setUsernameSuccessText("")
                                checkAvailability(e.target.value)
                            }}
                                error={usernameError}
                                errorText={usernameText}
                                domain={props.domain}
                                success={usernameSuccess}
                                successText={usernameSuccessText}
                            />
                        </div>
                        <div className="domain-name">@{props.domain}</div>
                    </div>
                    <div className="btn-container-right">
                        <SignupButtonContainer title="Next" primary onClick={onNextClick} />
                    </div>

                </div>

            </div>
        </div>

    )
}
export default SignupWrapperThree
