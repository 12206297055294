import React, { useEffect } from "react";
import SignupButtonContainer from "../../../../../Components/Button/SignupButtonContainer/SignupButtonContainer";
import ProgressBar from "../LoaderProgress/LoaderProgress";
import Modal from "../../Components/Modal/Modal";
import Done from "../../../../../Assets/Animations/animation_lm0b09rp.json";
import DNSChecking from "../../../../../Assets/Animations/CheckingDNS.json";
import CancelIcon from "../../../../../Assets/Icons/redCross.png";
import {
  domainOnboard,
  validateDNS,
  createAdmin,
  createUser,
  mxForceUpdate,
  setFirstLoginFalse,
} from "../../API_reqs";
import config from "../../../../../config";
import { useParams } from "react-router-dom";
import { authService } from "../../../../../services/AuthService";
import { useSignUpFlowContext } from "../../GlobalStates";
import { useHistory } from "react-router-dom/";

const SignupFooter = (props) => {
  const history = useHistory();
  const domainRegex = /^([\da-z.-]+)\.([a-z.]{2,6})$/i;
  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");

  const { domain_id } = useParams();

  const updateRecords = (list) => {
    setRecordStates(list);
    console.log(list);
    if (list[0] && list[1] && list[2] && (list[3] || list[4])) {
      console.log("All records are true");
      setRecordsDone(true);
      return true;
    }
    setRecordsDone(false);
    return false;
  };

  //Modal 1
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //Modal 2
  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };

  //Modal 3
  const openModal3 = () => {
    setShowModal3(true);
  };

  const closeModal3 = () => {
    setShowModal3(false);
  };

  const {
    //Global States
    selectedPage,
    setSelectedPage,
    loader,
    setLoader,
    setFooterHeight,
    upsellSelected,

    //Busienss Details page
    businessName,
    numberOfEmp,
    jobTitle,
    agree,
    setbusinessNameError,
    setnumberOfEmpError,
    setjobTitleError,
    setAgreeError,

    //Your Details page
    firstName,
    secondName,
    email,
    otpDone,
    number,
    otp,
    setfirstNameError,
    setsecondNameError,
    setemailError,
    setemailErrorText,
    setNumberError,
    setOtpError,
    otpEmail,
    // firstNameError,
    // secondNameError,
    // emailError,
    // numberError,
    // otpError,
    setNumberErrorText,

    //Domain
    domain,
    domainError,
    setDomainError,
    setDomainErrorText,

    //View DNS
    recordStates,
    setRecordStates,
    setDkim1Error,
    setDkim1ErrorText,
    setDkim2Error,
    setDkim2ErrorText,
    setMailCnameError,
    setMailCnameErrorText,
    setMxRecordError,
    setMxRecordErrorText,
    mxErrorAgree,
    selectDomain,
    recordsDone,
    setRecordsDone,
    mxRecordError,

    //Create Account
    fname,
    setFnameError,
    lname,
    setLnameError,
    address,
    // setAddressError,
    username,
    setUsernameError,
    headline,
    // setHeadlineError,
    password,
    setPasswordError,
    setPasswordErrorText,
    confirmPassword,
    setConfirmPasswordError,
    setConfirmPasswordErrorText,
    passDone,
    base64Image,
    setFnameErrorText,
    setLnameErrorText,
  } = useSignUpFlowContext();

  useEffect(() => {
    setFooterHeight(document.getElementById("footer").clientHeight);
  });

  useEffect(() => {
    if (
      localStorage.getItem("selectedPage") == 4 &&
      localStorage.getItem("upsellSelected") === "false"
    ) {
      setModalMessage(
        <>
          Thank you. We have sent the DNS records to your mail.
          <p style={{ color: "#147CFF" }}>"{otpEmail}"</p>
        </>
      );
      openModal();
      localStorage.removeItem("selectedPage");
      localStorage.removeItem("upsellSelected");
      localStorage.removeItem("businessName");
      localStorage.removeItem("numberOfEmp");
      localStorage.removeItem("jobTitle");
      localStorage.removeItem("firstName");
      localStorage.removeItem("secondName");
      localStorage.removeItem("email");
      localStorage.removeItem("number");
    }
  }, []);

  const handleNext = (page, loaderVal) => {
    setSelectedPage(page);
    setLoader(loader + loaderVal);
  };

  const handleBack = (page, loaderVal) => {
    if (selectedPage === 1) return;
    setSelectedPage(page);
    setLoader(loader - loaderVal);
  };

  const onNextClick = () => {
    if (selectedPage === 1) {
      //Business Details page
      if (businessName === "" || businessName.length < 2) {
        setbusinessNameError(true);
      }
      if (numberOfEmp === "") {
        setnumberOfEmpError(true);
      }
      if (jobTitle === "" || jobTitle.length < 2) {
        setjobTitleError(true);
      }
      if (businessName === "") {
        setbusinessNameError(true);
      }
      if (numberOfEmp.length < 1) {
        setnumberOfEmpError(true);
      }
      if (!agree) {
        setAgreeError(true);
      }

      if (
        businessName !== "" &&
        businessName.length >= 2 &&
        numberOfEmp !== "" &&
        agree &&
        jobTitle !== "" &&
        jobTitle.length >= 2
      ) {
        handleNext(selectedPage + 1, 30);
      }
    } else if (selectedPage === 2) {
      let firstNameErrorLocal,
        secondNameErrorLocal,
        emailErrorLocal,
        numberErrorLocal,
        otpErrorLocal = false;
      const mobileNumberRegex = /^(?:\+\d{11}|\d{3}[ -]?\d{3}[ -]?\d{4})$/;
      const nameRegex = /^[A-Za-z\s'-]{3,}$/;

      //Your Details page
      if (firstName === "" || !nameRegex.test(firstName)) {
        setfirstNameError(true);
        secondNameErrorLocal = true;
      }
      if (secondName === "" || !nameRegex.test(secondName)) {
        setsecondNameError(true);
        secondNameErrorLocal = true;
      }
      if (email === "") {
        setemailError(true);
        emailErrorLocal = true;
      }
      if (
        number === "" ||
        !mobileNumberRegex.test(number) ||
        number.length < 10
      ) {
        setNumberErrorText("Please provide a valid phone number");
        setNumberError(true);
        numberErrorLocal = true;
      }
      if (otp === "") {
        setOtpError(true);
        otpErrorLocal = true;
      }
      if (otpDone === false) {
        setemailError(true);
        setemailErrorText("Please verify your email");
        emailErrorLocal = true;
      }

      if (
        !firstNameErrorLocal &&
        !secondNameErrorLocal &&
        !emailErrorLocal &&
        !numberErrorLocal &&
        otpDone === true &&
        !otpErrorLocal
      ) {
        if (upsellSelected) {
          history.push("/confirm/up");
          localStorage.setItem("upsellSelected", true);
          localStorage.setItem("businessName", businessName);
          localStorage.setItem("numberOfEmp", numberOfEmp);
          localStorage.setItem("jobTitle", jobTitle);
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("secondName", secondName);
          localStorage.setItem("email", email);
          localStorage.setItem("number", number);
          localStorage.setItem("selectedPage", selectedPage);
        } else {
          handleNext(selectedPage + 1, 30);
        }
      }
    } else if (selectedPage === 4) {
      //Have Domain page
      if (domain !== "") {
        if (domainRegex.test(domain)) {
          if (!domainError) {
            domainOnboard({
              domain: domain,
              email: otpEmail,
              name_of_the_business: businessName,
              number_of_employees: parseInt(numberOfEmp),
              job_title: jobTitle,
              first_name: firstName,
              last_name: secondName,
              contact_number: number,
            })
              .then((response) => {
                if (response.status === 201) {
                  setLoader(100);
                  setModalMessage(
                    <>
                      We have sent the DNS records to your mail
                      <p style={{ color: "#147CFF" }}>"{otpEmail}"</p>
                    </>
                  );
                  openModal();
                  setTimeout(() => {
                    if (localStorage.getItem("upsellSelected") === "false") {
                      history.push("/signup-flow");
                      localStorage.setItem("selectedPage", 4);
                    }
                  }, 1000);
                } else if (response.status === 400) {
                  console.log(response);
                  setDomainErrorText("Domain already exists");
                  setDomainError(true);
                }
              })
              .catch((error) => {
                setDomainErrorText("Domain already exists");
                setDomainError(true);
                console.error("Error fetching data1:", error.message);
              });
            // handleNext(selectedPage + 1, 20);
          } else {
            setDomainErrorText();
            setDomainError(true);
          }
        } else {
          setDomainError(true);
          setDomainErrorText("Please provide a valid domain");
        }
      } else {
        setDomainError(true);
        setDomainErrorText("Domain is required");
      }
    } else if (selectedPage === 6) {
      //Select Domain Create admin page
      const nameRegex = /^[A-Za-z\s'-]+$/;

      console.log("Select Domain Create user page");
      console.log("Create Account page", passDone);

      if (fname === "" || !nameRegex.test(fname) || fname.length < 3) {
        setFnameErrorText("Please provide a valid name");
        setFnameError(true);
      }

      if (lname === "" || !nameRegex.test(lname) || lname.length < 3) {
        setLnameErrorText("Please provide a valid name");
        setLnameError(true);
      }

      // if (address === "") {
      //   setAddressError(true);
      // }

      if (username === "") {
        setUsernameError(true);
      }

      // if (headline === "") {
      //   setHeadlineError(true);
      // }

      if (password === "" || !passDone) {
        if (!passDone) {
          setPasswordErrorText("Password is not strong enough");
        }
        setPasswordError(true);
      }

      if (confirmPassword === "") {
        setConfirmPasswordError(true);
      }

      if (password !== confirmPassword) {
        setConfirmPasswordErrorText("Passwords do not match");
        setConfirmPasswordError(true);
      }

      if (
        passDone &&
        fname !== "" &&
        nameRegex.test(fname) &&
        fname.length >= 3 &&
        lname !== "" &&
        nameRegex.test(lname) &&
        lname.length >= 3 &&
        // address !== "" &&
        username !== "" &&
        // headline !== "" &&
        password === confirmPassword
      ) {
        console.log("Test");
        createUser({
          first_name: fname,
          last_name: lname,
          username: username,
          password: password,
          domain: selectDomain,
          recovery_email: otpEmail,
          profile_picture_encoded: base64Image.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),
          headline: headline,
        })
          .then(async (res) => {
            console.log("ok");
            console.log(res);

            if (res.status === 201) {
              setLoader(100);
              setModalMessage(
                <div>
                  Welcome to Zapmail
                  <div style={{ color: "#147CFF" }}>
                    "{fname} {lname}"
                  </div>
                </div>
              );
              openModal();

              await authService()
                .handleLogin(res.data, true)
                .then(() => {
                  localStorage.setItem(
                    "bixauth_accesstoken",
                    res.data.x_access_token
                  );
                  localStorage.setItem("token", res.data.refresh_token);
                  localStorage.setItem("is_first_login", true);
                  localStorage.setItem("bixauth_email", res.data.email);
                  setFirstLoginFalse({}, res.data.x_access_token).then(
                    (result) => {
                      console.log("done", result);
                    }
                  );
                  setTimeout(() => {
                    window.location.href = `${config.domain.app}`;
                  }, 1000);
                });
            }
          })
          .catch((error) => {
            console.error("Error creating user:", error);
            setModalMessage(
              <div>
                Issue detected:
                <div style={{ color: "red" }}></div>
                Please try again
              </div>
            );
            openModal3();
          });
      }
    } else if (selectedPage === 7) {
      //Confirm page
      setModalMessage(
        <>
          We sent meeting link to your mail{" "}
          <p style={{ color: "#147CFF" }}>"{email}"</p>
        </>
      );
      openModal();
    } else if (selectedPage === 8) {
      //I'm Done page
      setModalMessage(
        <>
          Verifying DNS Records for{" "}
          <p style={{ color: "#147CFF" }}>"{selectDomain}"</p>
        </>
      );
      let mx_state = false;
      if (!recordsDone && (!mx_state || !mxErrorAgree)) {
        openModal2();

        validateDNS(domain_id).then(async (res) => {
          console.log("clicked");
          console.log(res.data);
          closeModal2();

          setMxRecordErrorText(res.data.mx_validation.message);
          if (res.data.mx_validation.status === "warning") {
            setMxRecordError("warning");
            mx_state = false;
          } else if (res.data.mx_validation.status === "error") {
            setMxRecordError(true);
            mx_state = false;
          } else {
            setMxRecordError(false);
            mx_state = true;
            setMxRecordErrorText("");
          }

          if (res.data.cname_validation.dkim1.valid) {
            setDkim1Error(false);
          } else {
            setDkim1ErrorText(res.data.cname_validation.dkim1.reason);
            setDkim1Error(true);
          }

          if (res.data.cname_validation.dkim2.valid) {
            setDkim2Error(false);
          } else {
            setDkim2ErrorText(res.data.cname_validation.dkim2.reason);
            setDkim2Error(true);
          }

          if (res.data.cname_validation.mail_cname.valid) {
            setMailCnameError(false);
          } else {
            setMailCnameErrorText(res.data.cname_validation.mail_cname.reason);
            setMailCnameError(true);
          }

          // if (
          //   res.data.cname_validation.dkim1.valid &&
          //   res.data.cname_validation.dkim2.valid &&
          //   res.data.cname_validation.mail_cname.valid &&
          //   (mxErrorAgree || mx_state)
          // ) {
          //   setRecordsDone(true);
          // }

          updateRecords([
            res.data.cname_validation.dkim1.valid,
            res.data.cname_validation.dkim2.valid,
            res.data.cname_validation.mail_cname.valid,
            mxErrorAgree,
            mx_state,
          ]);

          console.log("clicked", recordsDone);
          console.log("clicked", mxErrorAgree);
          console.log("clicked", mx_state);
        });
      }
      if (recordsDone) {
        if (mxErrorAgree) {
          mxForceUpdate({
            domain_id: domain_id,
          })
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                setSelectedPage(9);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setSelectedPage(9);
        }
      }
    } else if (selectedPage === 9) {
      //Create Account page
      console.log("Create Account page", passDone);

      if (fname === "") {
        setFnameError(true);
      }

      if (lname === "") {
        setLnameError(true);
      }

      // if (address === "") {
      //   setAddressError(true);
      // }

      if (username === "") {
        setUsernameError(true);
      }

      // if (headline === "") {
      //   setHeadlineError(true);
      // }

      if (password === "" || !passDone) {
        if (!passDone) {
          setPasswordErrorText("Password is not strong enough");
        }
        setPasswordError(true);
      }

      if (confirmPassword === "") {
        setConfirmPasswordError(true);
      }

      if (password !== confirmPassword) {
        setConfirmPasswordErrorText("Passwords do not match");
        setConfirmPasswordError(true);
      }

      if (
        passDone &&
        fname !== "" &&
        lname !== "" &&
        // address !== "" &&
        username !== "" &&
        // headline !== "" &&
        password === confirmPassword
      ) {
        createAdmin({
          domain_id: domain_id,
          first_name: fname,
          last_name: lname,
          address: address,
          username: username,
          headline: headline,
          password: password,
          profile_picture_encoded: base64Image.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),
        })
          .then(async (res) => {
            if (res.status === 201) {
              setLoader(100);
              setModalMessage(
                <div>
                  Welcome to Zapmail
                  <div style={{ color: "#147CFF" }}>"{selectDomain}"</div>
                </div>
              );
              console.log(res);
              openModal();
              await authService()
                .handleLogin(res.data, true)
                .then(() => {
                  localStorage.setItem(
                    "bixauth_accesstoken",
                    res.data.x_access_token
                  );
                  localStorage.setItem("token", res.data.refresh_token);
                  localStorage.setItem("is_first_login", true);
                  localStorage.setItem("bixauth_email", res.data.email);
                  setFirstLoginFalse({}, res.data.x_access_token).then(
                    (result) => {
                      console.log("done", result);
                    }
                  );
                  setTimeout(() => {
                    window.location.href = `${config.domain.app}`;
                  }, 1000);
                });
            }
          })
          .catch((error) => {
            console.error("Error creating user:", error);
            setModalMessage(
              <div>
                Issue detected:
                <div style={{ color: "red" }}></div>
                Please try again
              </div>
            );
            openModal3();
          });
      }
    }
  };

  return (
    <div className="SignupFooter" id="footer">
      <Modal
        show={showModal}
        onClose={closeModal}
        lottie={Done}
        signUpFlow
        signUpFlowLottie
        message={modalMessage}
        fontSize="1.35rem"
      />
      <Modal
        show={showModal2}
        onClose={closeModal2}
        lottie={DNSChecking}
        signUpFlow
        signUpFlowLottie
        message={modalMessage}
        fontSize="1.35rem"
      />
      <Modal
        show={showModal3}
        onClose={closeModal3}
        headerImage={CancelIcon}
        signUpFlow
        message={modalMessage}
        fontSize="1.35rem"
      />
      <div className="button-container">
        {/* Back Button */}
        {selectedPage === 1 || selectedPage === 9 ? (
          ""
        ) : (
          <SignupButtonContainer
            title="Back"
            line
            onClick={() => {
              if (selectedPage === 5) {
                setSelectedPage(3);
              } else {
                handleBack(selectedPage - 1);
              }

              if (selectedPage === 7) {
                setSelectedPage(1);
              }

              if (selectedPage === 8) {
                setSelectedPage(1);
                setRecordsDone(false);
              }

              console.log("Back", selectedPage, loader);
            }}
          />
        )}

        {/* Next Button */}
        {selectedPage === 3 ||
        selectedPage === 5 ||
        (props.UserLink && selectedPage === 1) ? (
          ""
        ) : !recordsDone ? (
          <SignupButtonContainer
            title={
              selectedPage === 6 || selectedPage === 9
                ? "Create"
                : selectedPage === 7
                ? "Confirm"
                : selectedPage === 8
                ? "I'm Done"
                : "Next"
            }
            primary
            onClick={() => {
              onNextClick();
            }}
          />
        ) : (
          <SignupButtonContainer
            title={
              selectedPage === 6 || selectedPage === 9
                ? "Create"
                : selectedPage === 7
                ? "Confirm"
                : "Next"
            }
            primary
            onClick={() => {
              onNextClick();
            }}
          />
        )}
      </div>
      {props.UserLink ? null : (
        <div className="progress-bar">
          <ProgressBar percentage={loader} />
        </div>
      )}
    </div>
  );
};
export default SignupFooter;
