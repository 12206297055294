import React, { useEffect, useState } from "react";
import InputContainer from "../Signup/InputContainer/InputContainer";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import "./index.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import { useHistory, useParams } from "react-router-dom";
import { sendOTP, validateOTP } from "../SignUpFlow/API_reqs";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";

const PaymentBasic = (props) => {
  useEffect(() => {
  });
  const {
    selectedItem,
    setSelectedItem,
    firstName,
    setFirstName,
    secondName,
    setSecondName,
    email,
    setEmail
  } = useSignUpFlowContext();

  const history = useHistory();
  const params = useParams();

  const [emailError, setemailError] = useState(false);
  const [emailErrorText, setemailErrorText] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpDone, setOtpDone] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState("");

  useEffect(() => {
    console.log('Params: ', params)
    switch(params.item){
      case 'zappy':
        setSelectedItem('AI Website')
        break;
      case 'bulk':
        setSelectedItem('Bulk Email')
        break;
      case 'zappybulk':
        setSelectedItem('AI Website + Bulk Email')
        break;
    }

    let otp = localStorage.getItem('otp')
    if(otp){
      if(Date.now() < otp){
        setOtpSent(true)
        setTimer(otp-Date.now())
      }
      else{
        localStorage.removeItem('otp')
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if(timer > 0) setTimer(timer-1000)
    }, 1000);
  }, [timer]);

  const handleOTP = () => {
    sendOTP({email:email})
      .then(async (response) => {
        if (response.status === 201) {
          setOtpSent(true);
          localStorage.setItem('otp', Date.now()+90000)
          setTimer(90000)
        }
      })
      .catch((error) => {
        setemailError(true);
        setemailErrorText(
          "OTP Sending error. Please try again."
        );
      });
  }

  const handleOTPVerify = () => {
    validateOTP({
      email: email,
      otp: otp,
    })
    .then(async (response) => {
      if (response.status === 200) {
        setOtpError(false);
        setemailError(false);
        setOtpDone(true);
        handlePayment();
      } else if (response.status === 401) {
        setOtpErrorText("Invalid OTP. Please try again.");
        setOtpError(true);
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        setOtpErrorText("Invalid OTP. Please try again.");
      } else if (error.response.status === 403) {
        setOtpErrorText(
          "OTP Expired. Please request another OTP"
        );
      }
      setOtpError(true);
    });
  }

  const handlePayment = () => {
    history.push("/confirm/direct")
  };

  return (
    <>
    <div className="SignupFlow">
      <header className="header-container">
        <SignupNavbar />
      </header>
    </div>
    <div className="main-body-pay">
      <center className="inputs-wrapper">
        <h2>Enter your details for payment</h2>

        <InputContainer
          color="white"
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.088rem"
          placeholder="First Name"
          value={firstName}
          type="text"
          display={otpDone ? "none" : "flex"}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          error={false}
          errorText={'otpErrorText'}
        />

        <InputContainer
          color="white"
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.088rem"
          placeholder="Last Name"
          value={secondName}
          type="text"
          display={otpDone ? "none" : "flex"}
          onChange={(e) => {
            setSecondName(e.target.value);
          }}
          error={false}
          errorText={'otpErrorText'}
        />

        <InputContainer
          color="white"
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.088rem"
          placeholder="Email"
          value={email}
          type="text"
          display={otpDone ? "none" : "flex"}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          error={emailError}
          errorText={emailErrorText}
        />

        <div className="pay-sub-text">
          {!otpSent ? (
            <SignupButtonContainer
              title="Send OTP"
              line
              onClick={handleOTP}
            />
          )
          :(
            timer > 0 ? (
              'Time Left: ' + Math.floor(timer/1000) + 's'
            )
            :(
              <SignupButtonContainer
                title="Resend OTP"
                line
                onClick={handleOTP}
              />
            )
          )}
        </div>

        <div style={{display: otpSent ? "flex" : "none"}}>
          <InputContainer
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="OTP"
            value={otp}
            type="text"
            display="flex"
            onChange={(e) => {
              setOtp(e.target.value);
            }}
            error={otpError}
            errorText={otpErrorText}
          />
          <div className="pay-verify">
            <SignupButtonContainer
              title="Verify OTP"
              line
              onClick={handleOTPVerify}
            />
          </div>
        </div>

        {otpDone ? 'OTP verified' : ''}

        <div style={{display: otpDone ? "flex" : "none"}}>
          <SignupButtonContainer
            title="Go to pay"
            line
            onClick={handlePayment}
          />
        </div>
      </center>
    </div>
    </>
  );
};

export default PaymentBasic;
