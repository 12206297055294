import React, { useEffect } from "react";
import InputContainer from "../../../Signup/InputContainer/InputContainer";
import check from "../../../../../Assets/Icons/check.png";
import "./../../SignUpFlow.scss";
import { useSignUpFlowContext } from "../../GlobalStates";

const BusinessDetails = (props) => {
  useEffect(() => {
    setLoader(5);
  });
  const {
    setLoader,
    businessName,
    setBusinessName,
    numberOfEmp,
    setNumberOfEmp,
    jobTitle,
    setJobTitle,
    agree,
    setAgree,
    businessNameError,
    setbusinessNameError,
    numberOfEmpError,
    setnumberOfEmpError,
    jobTitleError,
    setjobTitleError,
    agreeError,
    setAgreeError,
    businessNameErrorText,
    numberOfEmpErrorText,
    jobTitleErrorText,
    headerHeight,
    pageWidth,
    footerHeight,
    selectedPage
  } = useSignUpFlowContext();

  return (
    <div
      className="business-details-wrapper"
      style={{
        marginTop: pageWidth < 1200 && selectedPage === 9 && `${headerHeight}px`,
        paddingBottom: pageWidth > 900 && `${footerHeight}px`,
      }}
    >
      <center>
        <h2>Business Details</h2>
        <InputContainer
          signUp
          color="white"
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.688rem"
          placeholder="Name of the business"
          value={businessName}
          type="businessName"
          onChange={(e) => {
            setBusinessName(e.target.value);
            setbusinessNameError(false);
          }}
          error={businessNameError}
          errorText={businessNameErrorText}
        />

        <InputContainer
          signUp
          color="white"
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.688rem"
          placeholder="Number of Employees"
          value={numberOfEmp}
          type="text"
          onChange={(e) => {
            const regex = /^[0-9\b]+$/;
            if (e.target.value === "" || regex.test(e.target.value)) {
              setNumberOfEmp(e.target.value);
              setnumberOfEmpError(false);
            }
          }}
          error={numberOfEmpError}
          errorText={numberOfEmpErrorText}
        />

        <InputContainer
          signUp
          color="white"
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.688rem"
          placeholder="Designation/Job Title"
          value={jobTitle}
          type="jobTitle"
          onChange={(e) => {
            setJobTitle(e.target.value);
            setjobTitleError(false);
          }}
          error={jobTitleError}
          errorText={jobTitleErrorText}
        />
        <div style={{ display: "flex" }}>
          <div
            className={`check-container ${agreeError ? "check-error" : ""}`}
            onClick={() => {
              setAgree(!agree);
              setAgreeError(false);
            }}
          >
            <div
              className={`check-box ${
                agreeError ? "checked-error" : agree ? "checked" : ""
              }`}
            >
              {agree && <img alt="" src={check} className="check" />}
            </div>
            I’m authorized to create business emails on my company’s behalf
          </div>
        </div>
      </center>
    </div>
  );
};

export default BusinessDetails;
