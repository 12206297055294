import React, { useEffect } from "react";
import "../../SignUpFlow.scss";
import SignupButtonContainer from "../../../../../Components/Button/SignupButtonContainer/SignupButtonContainer";
import Loading from "../../../../../Assets/Animations/Loading.json";
import Lottie from "lottie-react";
import { getDomains } from "../../API_reqs";
import { useSignUpFlowContext } from "../../GlobalStates";

const SelectDomain = () => {
  const { setSelectedPage, setSelectDomain, setLoader,pageWidth,footerHeight } =
    useSignUpFlowContext();
  const [domains, setDomains] = React.useState([]); // This is the array of domains that will be fetched from the backend
  const [domainsLoaded, setDomainsLoaded] = React.useState(false); // This is the array of domains that will be fetched from the backend

  useEffect(() => {
    setLoader(70);
    getDomains().then((res) => {
      let domains = [];
      // console.log(res.data.domains);
      res.data.domains.map((domain) => {
        domains.push(domain.domain_name);
      });
      setDomains(domains);
      setDomainsLoaded(true);
    });
  }, [domains]);

  return (
    <div className="business-details-wrapper" style={{
      paddingBottom: pageWidth > 900 && `${footerHeight}px`,
    }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 className="your-details-header">Select a domain</h2>
        <div
          style={{
            margin: "0 0 1.68rem",
            color: "white",
            fontStyle: "italic",
            fontWeight: "200",
          }}
        >
          <p>
            You can select any of these domains and create a personal account.
          </p>
        </div>
        <div className="domain-list">
          {domainsLoaded ? domains.map((domain, index) => (
            <div
              className="domain-item"
              key={index} // Don't forget to add a unique key when mapping over an array
            >
              <div className="domain">{domain}</div>
              <div>
                <SignupButtonContainer
                  title="Select"
                  line
                  onClick={() => {
                    console.log("Selected domain: ", domain);
                    setSelectDomain(domain);
                    setSelectedPage(6);
                    setLoader(90);
                  }}
                />
              </div>
            </div>
          )):<center><Lottie
          animationData={Loading}
          loop={true}
          className="signUpModalLottie"
        /></center>}
        </div>
      </div>
    </div>
  );
};
export default SelectDomain;
