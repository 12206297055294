import React from "react";
import "./SignupButtonContainerStyles.scss"

const SignupButtonContainer = (props) => {
    return (
        <div className={`ButtonContainer ${props.primary ? "ButtonContainer-Primary" : props.danger ? "ButtonContainer-Danger" : props.cancel ? "ButtonContainer-Cancel" : props.line? "ButtonContainer-Line " : props.disabled? "ButtonContainer-Disabled" : props.grey ? "ButtonContainer-Grey" :"ButtonContainer-Secondary"}`}
            onClick={props.onClick}
            style={{ width: props.width ? props.width : "", fontSize: props.fontSize ? props.fontSize : "", margin: props.margin ? props.margin : "" }}
        >
            {props.isLoading ? <div className="sign-loader" />
                : props.title}
        </div>
    )

}
export default SignupButtonContainer