import React, { useEffect, useState } from "react";
import InputContainer from "../Signup/InputContainer/InputContainer";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import "./index.scss";
import "../SignUpFlow/SignUpFlow.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import { useHistory, useParams } from "react-router-dom";
import {
  Payhere,
  AccountCategory,
  PayhereSubscription,
  SubscriptionParams,
  Customer,
  Month,
  CurrencyType,
} from "@payhere-js-sdk/client";
import axios from "axios";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";
import check from "../../../Assets/Icons/check.png";

const PaymentConfirm = (props) => {
  useEffect(() => {});
  const {
    upsellSelected,
    setUpsellSelected,
    zappyUpsell,
    setZappyUpsell,
    bulkUpsell,
    setBulkUpsell,
    selectedItem,
    setSelectedItem,
    firstName,
    secondName,
    email,
    number,
  } = useSignUpFlowContext();

  const history = useHistory();
  const params = useParams();

  // Payhere.init("1224576", AccountCategory.SANDBOX);
  Payhere.init("224315", AccountCategory.LIVE);

  const [phone, setPhone] = useState(number);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [amount, setAmount] = useState("");
  const [hash, setHash] = useState("");

  function onPayhereSubscriptionError(errorMsg) {
    alert(errorMsg);
  }

  const getInvoice = () => {
    let requestParam =
      "?amount=" +
      amount +
      "&currency=USD&items=" +
      selectedItem +
      "&first_name=" +
      firstName +
      "&last_name=" +
      secondName +
      "&email=" +
      email;
    if (amount != "") {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "get",
        // baseURL: "http://localhost:4000",
        // baseURL: "https://api.bixchat.xyz/auth",
        baseURL: "https://api.zapmail.app/auth",
        url: "/invoice/create" + requestParam,
      })
        .then((response) => {
          setInvoice(response.data.invoice_id);
          setTimeout(() => {
            getHash(amount, response.data.invoice_id);
          }, 1000);
        })
        .catch((error) => {
          // reject(error);
        });
    }
  };

  const getHash = (amount_i, invoice_id) => {
    let requestParam =
      "?amount=" + amount + "&currency=USD&invoice=" + invoice_id;
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      // baseURL: "http://localhost:4000",
      // baseURL: "https://api.bixchat.xyz/auth",
      baseURL: "https://api.zapmail.app/auth",
      url: "/payhere/hash" + requestParam,
    })
      .then((response) => {
        setHash(response.data.payhere_hash);
      })
      .catch((error) => {
        // reject(error);
      });
  };

  const handlePayhere = () => {
    try {
      const customer = new Customer({
        first_name: firstName,
        last_name: secondName,
        phone: phone,
        email: email,
        address: address,
        city: city,
        country: country,
      });

      const subscriptionData = new SubscriptionParams({
        // returnUrl: "https://auth.bixchat.xyz/" + (params.redirect == 'up' ? "signup-flow-details" : "payment/success"),
        // cancelUrl: "https://auth.bixchat.xyz/" + (params.redirect == 'up' ? "signup-flow-details" : "payment/cancel"),
        // notifyUrl: "https://api.bixchat.xyz/auth/payhere/notify",
        returnUrl: "https://auth.zapmail.app/" + (params.redirect == 'up' ? "signup-flow-details" : "payment/success"),
        cancelUrl: "https://auth.zapmail.app/" + (params.redirect == 'up' ? "signup-flow-details" : "payment/cancel"),
        notifyUrl: "https://api.zapmail.app/auth/payhere/notify",
        order_id: invoice,
        itemTitle: selectedItem,
        recurrence: new Month(1),
        duration: "Forever",
        currency: CurrencyType.USD,
        amount: amount,
        hash: hash,
      });
      const subscription = new PayhereSubscription(
        customer,
        subscriptionData,
        onPayhereSubscriptionError
      );
      subscription.start();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    switch (selectedItem) {
      case "AI Website":
        setAmount("10.00");
        break;
      case "Bulk Email":
        setAmount("5.00");
        break;
      case "AI Website + Bulk Email":
        setAmount("15.00");
        break;
    }
  }, []);

  useEffect(() => {
    getInvoice();
  }, [amount]);

  return (
    <>
      <div className="SignupFlow">
        <header className="header-container">
          <SignupNavbar />
        </header>
      </div>
      <div className="main-body-pay">
        <center className="inputs-wrapper">
          <h2>Enter your details for payment</h2>

          <InputContainer
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Phone"
            value={phone}
            type="text"
            display="flex"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />

          <InputContainer
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Address"
            value={address}
            type="text"
            display="flex"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />

          <InputContainer
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="City"
            value={city}
            type="text"
            display="flex"
            onChange={(e) => {
              setCity(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />

          <InputContainer
            color="white"
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Country"
            value={country}
            type="text"
            display="flex"
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />
          <div style={{ display: "flex" }}>
            <div
              className={`check-container ${agreeError ? "check-error" : ""}`}
              onClick={() => {
                setAgree(!agree);
                setAgreeError(false);
              }}
            >
              <div
                className={`check-box ${
                  agreeError ? "checked-error" : agree ? "checked" : ""
                }`}
              >
                {agree && <img alt="" src={check} className="check" />}
              </div>
              I agree to the <a href="https://getzapmail.com/legal/" style={{marginLeft: '5px'}}>Terms & Conditions</a>
            </div>
          </div>
          <div className="pay-verify">
            <SignupButtonContainer
              title="Confirm & Pay"
              line
              onClick={() => {
                if (!agree) {
                  setAgreeError(true);
                  return;
                }
                handlePayhere();
              }}
            />
          </div>
        </center>
      </div>
    </>
  );
};

export default PaymentConfirm;
