import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";

const PaymentCancel = (props) => {
  useEffect(() => {
  });
  const {
  } = useSignUpFlowContext();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = 'https://getzapmail.com'
    }, 4000);
  }, []);

  return (
    <>
    <div className="SignupFlow">
      <header className="header-container">
        <SignupNavbar />
      </header>
    </div>
    <div className="main-body-pay">
      <center className="inputs-wrapper">
        <h2>Sorry, there has been an issue with your payment</h2>
      </center>
    </div>
    </>
  );
};

export default PaymentCancel;
