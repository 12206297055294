import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AppContext from "../../../ContextApi/AppContext";
import "./LoginStyles.scss";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import logo from "../../../Assets/Images/logo.png";

import UsernameInput from "../../../Components/UsernameInput/UsernameInput";
import Button from "../../../Components/Button/Button";
import { authService } from "../../../services/AuthService";
import config from "../../../config";
import LoadingPage from "../../LoadingPage/LoadingPage";
import CookieService from "../../../services/CookieService";


const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const newContext = useContext(AppContext);
  const loginBtn = useRef(null);
  const [userNameError, setUserNameError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const [isPwdFocus, setIsPwdFocus] = useState(false);
  const location = useLocation();
  const [usernameModal, setUsernameModal] = useState(false);
  const [appLoading, setAppLoading] = useState(true);
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  const clearCookie = () => {
    let date = new Date();
    const options = {
      path: "/",
      domain: config.cookieDomain,
      expires: date,
    };
    localStorage.clear()
    CookieService.remove("user", options);
    CookieService.remove("users", options);
  }
  useEffect(() => {
    let logoutId = params.get("logoutId");
    let switchingId = params.get("switchingId");
    if (logoutId) {
      authService()
        .handleMultipleUserLogout(logoutId)
        .then((success) => {
          if (success) {
            window.location.href = `${config.domain.app}`;
          } else {
            clearCookie()
            setAppLoading(false);
            history.push({
              pathname: "/",
            });
          }
        });
    } else if (switchingId) {
      authService()
        .handleSwitchingUser(switchingId)
        .then((success) => {
          if (success) {
            window.location.href = `${config.domain.app}`;
          } else {
            clearCookie()
            setAppLoading(false);
            history.push({
              pathname: "/",
            });
          }
        });
    } else {
      setAppLoading(false);
    }
  }, []);
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        if (userName === "" && password === "") {
          setPwdError(true);
          setUserNameError(true);
        } else if (userName === "" && password !== "") {
          setUserNameError(true);
        } else if (userName !== "" && password === "") {
          setPwdError(true);
        } else {
          setPwdError(false);
          setUserNameError(false);
          loginBtn.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [userName, password]);

  const handleUsername = (e) => {
    let userNameValue = e.target.value.trim();
    if (userNameError) {
      if (userNameValue !== "") {
        setUserNameError(false);
      }
    }
    setUserName(userNameValue);
  };

  const handlePassword = (e) => {
    let passwordValue = e.target.value;
    if (pwdError) {
      if (passwordValue !== "") {
        setPwdError(false);
      }
    }
    setPassword(passwordValue);
  };
  const removeFocus = () => {
    setIsUsernameFocus(false);
    setIsPwdFocus(false);
  };
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };
  const contentToShow = () => {
    if (!usernameModal) {
      return (
        <div className="loginwrapper">
          <div className="header">Login</div>
          <div className="input-wrapper">
            <UsernameInput
              type="text"
              isUsernameFocus={isUsernameFocus}
              placeholder={isUsernameFocus ? "" : "Email"}
              value={userName}
              onChange={(e) => {
                newContext?.setLoggedFailed(false);
                handleUsername(e);
              }}
              userNameError={userNameError}
              onFocus={() => {
                setIsUsernameFocus(true);
                setTimeout(() => window.scrollTo(0, 100), 0);
              }}
              onBlur={() => setIsUsernameFocus(false)}
              name="username"
              from={location?.state?.from}
            />
            {userNameError ? <div className="form-fail">* Required</div> : ""}
          </div>

          <div className="input-wrapper">
            <UsernameInput
              type={showPassword ? "text" : "password"}
              placeholder={isPwdFocus ? "" : "Password"}
              onChange={(e) => {
                newContext?.setLoggedFailed(false);
                handlePassword(e);
              }}
              isPwdFocus={isPwdFocus}
              pwdError={pwdError}
              onFocus={() => {
                setIsPwdFocus(true);
                setTimeout(() => window.scrollTo(0, 100), 100);
              }}
              onBlur={() => setIsPwdFocus(false)}
              name="password"
              value={password}
              from={location?.state?.from}
            />
            {pwdError ? <div className="form-fail">* Required</div> : ""}
            {newContext?.loggedFailed ? (
              <div className="form-fail">
                <img src={danger} alt="" className="danger-login" />
                Incorrect Username or Password
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="forgetText">
            <span
              className="forgetTextLink"
              onClick={() => {
                if (validateEmail(userName)) {
                  newContext.getRecovery(userName).then((data) => {
                    if (data.recovery_email !== "") {
                      history.push({
                        pathname: "/forget-password",
                        state: {
                          from: 'login',
                          email: userName,
                          recovery_email: data.recovery_email
                        }
                      })
                    } else {
                      setPwdError(false);
                      setUserNameError(false);
                      setUsernameModal(true);
                    }

                  })
                } else {
                  setPwdError(false);
                  setUserNameError(false);
                  setUsernameModal(true);
                }


              }}
            >
              Forgot Password
            </span>
          </div>

          <Button
            ref={loginBtn}
            loading={newContext?.loginIsLoading}
            onClick={() => {
              removeFocus();
              if (userName === "" && password === "") {
                setPwdError(true);
                setUserNameError(true);
              } else if (userName === "" && password !== "") {
                setUserNameError(true);
              } else if (userName !== "" && password === "") {
                setPwdError(true);
              } else {
                newContext?.loginClicked(userName.toLowerCase(), password);
              }
              if (newContext.loggingSuccess) {
                setUserName("");
                setPassword("");
              }
            }}
            buttonText="Sign In"
          />
          <div className="personal" onClick={() => {
            history.push({
              pathname: "/signup",
            });
          }}>Create your personal account</div>
        </div>
      );
    } else if (usernameModal) {
      return (
        <div className="forgot-password">
          <div className="forgot-password-header">Forgot Password</div>
          <div className="forgot-password-text">
            If you want to <span>reset password</span>, Contact the admin of
            your organization
          </div>
          <div
            className="btn-back"
            onClick={() => {
              setUserName("");
              setPassword("");
              setUsernameModal(false);
            }}
          >
            Back
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {appLoading ? (
        <LoadingPage />
      ) : (
        <div className="login">
          <div className="_loginContainer">
            <div className="form-wrapper">
              <div className="header-container">
                <img alt="" src={logo} className="image" />
              </div>
              {contentToShow()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Login;
