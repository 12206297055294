import React, { useEffect } from "react";
import SignupNavbar from "../../Components/SignupNavbar/SignupNavbar";
import SignupFooter from "../../Components/SignupFooter/SignupFooter";
import "../../SignUpFlow.scss";
import InitialPage from "./InitialPage/InitialPage";
import ScheduleMeeting from "./ScheduleMeeting/ScheduleMeeting";
import ViewDNS from "./ViewDNS/ViewDNS";
import CreateAccount from "../CreateAccount/CreateAccount";
import { useParams } from "react-router-dom";
import { getDNS } from "../../API_reqs";
import Lottie from "lottie-react";
import loading from "../../../../../Assets/Animations/Loading.json";
import { useSignUpFlowContext } from "../../GlobalStates";

const WelcomePage = (props) => {
  const {
    selectedPage,
    setEmail,
    setSelectDomain,
    dnsRecords,
    setDnsRecords,
    setRecordStates,
    setPageWidth,
    footerHeight,
    setDkim1Error,
    setDkim2Error,
    setMailCnameError,
    setMxRecordError,
  } = useSignUpFlowContext();

  const { domain_id } = useParams();
  const [pageLoaded, setPageLoaded] = React.useState(false);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    setEmail("");
    getDNS(domain_id)
      .then((res) => {
        console.log(res);
        setDnsRecords(res.data);
        setSelectDomain(res.data.domain);
        // setDkim1Error("None");
        // setDkim2Error("None");
        // setMailCnameError("None");
        // setMxRecordError("None");
        // setRecordStates([
        //   res.data.cname_records.dkim1.valid,
        //   res.data.cname_records.dkim2.valid,
        //   res.data.cname_records.mail_cname.valid,
        //   res.data.mx_record.valid
        // ]);

        setPageLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setDnsRecords([
          // {
          //   cname_records: {
          //     dkim1: {
          //       data: "N/A",
          //       host: "N/A",
          //       type: "CNAME",
          //       valid: false,
          //     },
          //     dkim2: {
          //       data: "N/A",
          //       host: "N/A",
          //       type: "CNAME",
          //       valid: false,
          //     },
          //     mail_cname: {
          //       data: "N/A",
          //       host: "N/A",
          //       type: "CNAME",
          //       valid: false,
          //     },
          //   },
          //   mx_record: {
          //     data: "N/A",
          //     host: "N/A",
          //     type: "MX",
          //     priority: false,
          //   },
          // },
        ]);
        setPageLoaded(true);
      });
    console.log(dnsRecords);
  }, []);

  return pageLoaded ? (
    <div className="SignupFlow">
      <div>
        <SignupNavbar />
      </div>
      <div className="business-details"
      style={{
        height: `calc(100vh - ${footerHeight}px)`,
      }}
      >
        {selectedPage === 1 && <InitialPage />}
        {selectedPage === 7 && <ScheduleMeeting />}
        {selectedPage === 8 && <ViewDNS />}
        {selectedPage === 9 && <CreateAccount CreateAccount />}
      </div>
      <div>
        <SignupFooter UserLink />
      </div>
    </div>
  ) : (
    <div className="SignupFlow">
      <div>
        <SignupNavbar />
      </div>
      <div className="business-details"
      style={{
        height: `calc(100vh - ${footerHeight}px)`,
      }}
      >
        <h2 className="your-details-header">
        <Lottie
                  animationData={loading}
                  loop={true}
                  className="signUpModalLottie"
                />
        </h2>
      </div>
      <div>
        <SignupFooter UserLink />
      </div>
    </div>
  );
};

export default WelcomePage;
