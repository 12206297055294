import axios from "axios";
import config from "../../../config";

export const sendOTP = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "/otp/send",
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateOTP = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "/otp/validate",
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const domainOnboard = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "/onboard",
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDNS = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/domain/records/${data}`,
      // data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateDNS = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/domain/records/${data}`,
      // data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createAdmin = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/onboard/admin_register`,
      data: data,
    })
      .then((response) => {
        resolve(response);
        console.log(response.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createUser = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/user/public_signup`,
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDomains = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/domain/public`,
      // data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkUsername = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/user/email_validation/${data}`,
      // data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const mxForceUpdate = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/onboard/force_update_mx`,
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setFirstLoginFalse = (data,token) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
      },
      method: "put",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: `/user/first-login`,
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
