import React, { useState, createContext, useContext } from "react";

const SignUpFlowContext = createContext();

export const SignUpFlowContextProvider = ({ children }) => {

  //   Page Navigation
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);

  const [selectedPage, setSelectedPage] = useState(1);
  const [loader, setLoader] = useState(10);

  // Upsell Details
  const [upsellSelected, setUpsellSelected] = useState(false);
  const [zappyUpsell, setZappyUpsell] = useState(false);
  const [bulkUpsell, setBulkUpsell] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Zappy");

  //   Business Details
  const [businessName, setBusinessName] = useState("");
  const [numberOfEmp, setNumberOfEmp] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [agree, setAgree] = useState(false);
  const [businessNameError, setbusinessNameError] = useState(false);
  const [numberOfEmpError, setnumberOfEmpError] = useState(false);
  const [jobTitleError, setjobTitleError] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [businessNameErrorText, setbusinessNameErrorText] = useState(
    "Valid business Name is required"
  );
  const [numberOfEmpErrorText, setnumberOfEmpErrorText] = useState(
    "Number of Employees is required"
  );
  const [jobTitleErrorText, setjobTitleErrorText] = useState(
    "Valid job Title is required"
  );

  //   Your Details
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [sideButtonText, setSideButtonText] = useState("Send OTP");
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpDone,setOtpDone] = useState(false);
  const [firstNameError, setfirstNameError] = useState(false);
  const [secondNameError, setsecondNameError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState(
    "Please provide a valid name"
  );
  const [secondNameErrorText, setsecondNameErrorText] = useState(
    "Please provide a valid name"
  );
  const [emailErrorText, setemailErrorText] = useState("Email is required");
  const [numberErrorText, setNumberErrorText] = useState("Number is required");
  const [otpErrorText, setOtpErrorText] = useState("OTP is required");
  const [emailSubText, setEmailSubText] = useState("This will be used to contact you during the setup. Please provide a valid email");
  const [timeOut, setTimeOut] = useState(false);
  const [minutes, setMinutes] = useState(Math.floor(0 / 60));
  const [seconds, setSeconds] = useState(0 % 60);

  //   Domain
  const [domain, setDomain] = useState("");
  const [domainError, setDomainError] = useState(false);
  const [domainErrorText, setDomainErrorText] = useState("Domain is required");
  
  //   Select Doamin
  const [selectDomain, setSelectDomain] = useState("");

  //   Create Account
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("Password is required");
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("Confirm Password is required");
  const [passDone, setPassDone] = useState(false);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [addressErrorText, setAddressErrorText] = useState("Address is required");
  const [fname, setFname] = useState("");
  const [fnameError, setFnameError] = useState(false);
  const [fnameErrorText, setFnameErrorText] = useState("First Name is required");
  const [lname, setLname] = useState("");
  const [lnameError, setLnameError] = useState(false);
  const [lnameErrorText, setLnameErrorText] = useState("Last Name is required");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [usernameErrorText, setUsernameErrorText] = useState("Username is required");
  const [headline, setHeadline] = useState("");
  const [headlineError, setHeadlineError] = useState(false);
  const [headlineErrorText, setHeadlineErrorText] = useState("Headline is required");
  const [base64Image, setBase64Image] = useState("");

  //   ViewDNS
  const [dnsRecords, setDnsRecords] = useState([]);
  const [recordStates, setRecordStates] = useState([]);
  const [recordsDone, setRecordsDone] = useState(false);
  const [dkim1Error, setDkim1Error] = useState(false);
  const [dkim1ErrorText, setDkim1ErrorText] = useState("DKIM1 is not valid");
  const [dkim2Error, setDkim2Error] = useState(false);
  const [dkim2ErrorText, setDkim2ErrorText] = useState("DKIM2 is not valid");
  const [mailCnameError, setMailCnameError] = useState(false);
  const [mailCnameErrorText, setMailCnameErrorText] = useState("Mail Cname is not valid");
  const [mxRecordError, setMxRecordError] = useState(false);
  const [mxRecordErrorText, setMxRecordErrorText] = useState("MX Record is not valid");
  const [mxErrorAgree, setMxErrorAgree] = useState(false);
  
  return (
    <SignUpFlowContext.Provider
      value={{
        //Page Navigation
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        pageWidth,
        setPageWidth,
        
        //Upsell Details
        upsellSelected,
        setUpsellSelected,
        zappyUpsell,
        setZappyUpsell,
        bulkUpsell,
        setBulkUpsell,
        selectedItem,
        setSelectedItem,

        //Business Details
        selectedPage,
        setSelectedPage,
        loader,
        setLoader,
        businessName,
        setBusinessName,
        numberOfEmp,
        setNumberOfEmp,
        jobTitle,
        setJobTitle,
        agree,
        setAgree,
        jobTitleError,
        setjobTitleError,
        agreeError,
        setAgreeError,
        businessNameErrorText,
        setbusinessNameErrorText,
        numberOfEmpErrorText,
        setnumberOfEmpErrorText,
        jobTitleErrorText,
        setjobTitleErrorText,
        businessNameError,
        setbusinessNameError,
        numberOfEmpError,
        setnumberOfEmpError,

        //Your Details
        firstName,
        setFirstName,
        secondName,
        setSecondName,
        email,
        setEmail,
        otpEmail,
        setOtpEmail,
        otpSent,
        setOtpSent,
        sideButtonText,
        setSideButtonText,
        number,
        setNumber,
        otp,
        setOtp,
        firstNameError,
        setfirstNameError,
        secondNameError,
        setsecondNameError,
        emailError,
        setemailError,
        firstNameErrorText,
        setfirstNameErrorText,
        secondNameErrorText,
        setsecondNameErrorText,
        emailErrorText,
        otpError,
        setOtpError,
        setemailErrorText,
        numberError,
        setNumberError,
        numberErrorText,
        setNumberErrorText,
        setOtpErrorText,
        otpErrorText,
        otpDone,
        setOtpDone,
        emailSubText,
        setEmailSubText,
        minutes,
        setMinutes,
        seconds,
        setSeconds,
        timeOut,
        setTimeOut,

        //Domain
        domain,
        setDomain,
        domainError,
        setDomainError,
        domainErrorText,
        setDomainErrorText,

        //Select Domain
        selectDomain,
        setSelectDomain,

        //Create Account
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        passwordError,
        setPasswordError,
        confirmPasswordError,
        setConfirmPasswordError,
        passwordErrorText,
        setPasswordErrorText,
        confirmPasswordErrorText,
        setConfirmPasswordErrorText,
        passDone,
        setPassDone,
        address,
        setAddress,
        addressError,
        setAddressError,
        addressErrorText,
        setAddressErrorText,
        fname,
        setFname,
        fnameError,
        setFnameError,
        fnameErrorText,
        setFnameErrorText,
        lname,
        setLname,
        lnameError,
        setLnameError,
        lnameErrorText,
        setLnameErrorText,
        username,
        setUsername,
        usernameError,
        setUsernameError,
        usernameErrorText,
        setUsernameErrorText,
        headline,
        setHeadline,
        headlineError,
        setHeadlineError,
        headlineErrorText,
        setHeadlineErrorText,
        base64Image,
        setBase64Image,

        //ViewDNS
        dnsRecords,
        setDnsRecords,
        recordsDone,
        setRecordsDone,
        recordStates,
        setRecordStates,
        dkim1Error,
        setDkim1Error,
        dkim1ErrorText,
        setDkim1ErrorText,
        dkim2Error,
        setDkim2Error,
        dkim2ErrorText,
        setDkim2ErrorText,
        mailCnameError,
        setMailCnameError,
        mailCnameErrorText,
        setMailCnameErrorText,
        mxRecordError,
        setMxRecordError,
        mxRecordErrorText,
        setMxRecordErrorText,
        mxErrorAgree,
        setMxErrorAgree,
        
      }}
    >
      {children}
    </SignUpFlowContext.Provider>
  );
};

export const useSignUpFlowContext = () => useContext(SignUpFlowContext);
